// Imports
// ------
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Row, Column } from '@waffl';
import { lightDarkMode } from '@states/darkmode';
import { observer } from 'mobx-react';

// Styles
// ------
import {
    Jacket,
    Title,
    Intro,
    Label,
    Content
} from './styles';

// Component
// ------
const LegalContent = ({ title, intro, content }) => (
    <Jacket pad>
        <Row isExpanded isEqual>
            <Column small={12} medium={6} large={5}>
                <Title>{title}</Title>
            </Column>
            <Column small={12} medium={6} pushOnLarge={1}>
                {/* Intro */}
                <Intro dangerouslySetInnerHTML={{ __html: intro }} />

                {/* Content */}
                {content.map((block, i) => (
                    <React.Fragment key={"content-" + i}>

                        {/* Text block component (for now not componentised) */}
                        {block.__typename === "DatoCmsLegalText" && (
                            <>
                                {block.label && <Label>{block.label}</Label>}
                                <Content isDark={lightDarkMode.isDark}>{ReactHtmlParser(block.content)}</Content>
                            </>
                        )}

                    </React.Fragment>
                ))}
            </Column>
        </Row>
    </Jacket>
);

export default observer(LegalContent);