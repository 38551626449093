// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, H1, Em, ContentBlock } from '@tackl';
import { emStyles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)`
    padding-top: 12rem;
`;

export const Title = styled(H1)`
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 3.6rem;

    ${breakup.medium`
        position: sticky;
        top: 10rem; left: 0;
    `}
`;

export const Intro = styled.div`
    ${emStyles}
    margin-bottom: 8rem;

    ${breakup.medium` margin-bottom: 12rem; `}
`;

export const Label = styled(Em)`
    margin-bottom: 3.6rem;
    opacity: 0.6;
`;

export const Content = styled(ContentBlock)(props => css`
    margin-bottom: 3.6rem;

    a {
        &:after {
            background: ${props.isDark ? props.theme.bc1 : props.theme.bc2} !important;
        }
    }
`);