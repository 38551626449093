// Imports
// ------
import React from 'react';
import Footer from '@parts/Footer';
import Chat from '@parts/Chat';
import LegalContent from '@parts/LegalContent';
import { graphql } from 'gatsby';

// Component
// ------
const Legal = ({ data }) => (
    <>
        <LegalContent
            title={data.legal.title}
            intro={data.legal.introductionNode.childMarkdownRemark.html}
            content={data.legal.content}
        />

        <Footer
            legals={data.legals.edges}
            blurb={data.global.footerBlurbNode.childMarkdownRemark.html}
            locations={data.global.locations}
        />

        <Chat />
    </>
)

export default Legal;

// GraphQL
// ------
export const query = graphql`
    query LegalPage($slug: String!) {
        legal: datoCmsLegal(slug: { eq: $slug }) {
            title
            introductionNode {
                childMarkdownRemark {
                    html
                }
            }

            content {
                __typename

                ... on DatoCmsLegalText {
                    label
                    content
                }
            }
        }

        legals: allDatoCmsLegal(filter: { slug: { nin: ["information-security-policy", "cookie-policy"] }}) {
            edges {
                node {
                    title
                    slug
                }
            }
        }

        global: datoCmsGlobalStuff {
            footerBlurbNode {
                childMarkdownRemark {
                    html
                }
            }

            locations {
                __typename
                ... on DatoCmsLocation {
                    locationName
                }
            }
        }
    }
`;